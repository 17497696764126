import { omit } from 'lodash';
// eslint-disable-next-line max-len
import enumNationwideOperationClientStatus from '../../../enum/nationwideOperations/enumNationwideOperationClientStatus';
import enumParticipationChildStatus from '../../../enum/participations/enumParticipationChildStatus';

/**
 *
 * @param {NationwideOperationParticipation} nationwideOperationParticipation
 */
export const orderedProducts = (nationwideOperationParticipation) => {
  if (nationwideOperationParticipation == null) return null;
  return nationwideOperationParticipation.orderedQuantity;
};

/**
 *
 * @param {NationwideOperationParticipation} nationwideOperationParticipation
 */
export const minimumToOrder = (nationwideOperationParticipation) => {
  if (nationwideOperationParticipation == null) return null;
  return nationwideOperationParticipation.totalQuantityToCommand;
};

/**
 *
 * @param {NationwideOperationParticipation} nationwideOperationParticipation
 */
export const missingProducts = (nationwideOperationParticipation) => {
  if (nationwideOperationParticipation == null) return null;
  return Math.max(
    minimumToOrder(nationwideOperationParticipation) - orderedProducts(nationwideOperationParticipation),
    0,
  );
};

/**
 *
 * @param {NationwideOperationParticipation} nationwideOperationParticipation
 */
export const progress = (nationwideOperationParticipation) => {
  if (nationwideOperationParticipation == null) return null;
  if (orderedProducts(nationwideOperationParticipation) === 0) return '0';
  return (
    (orderedProducts(nationwideOperationParticipation) / minimumToOrder(nationwideOperationParticipation)) *
    100
  ).toFixed(2);
};

export const getClientsGroupStatus = (clients) => {
  const { status } = clients[0];
  return enumNationwideOperationClientStatus[status];
};

export const getClientsAllowedForParticipationChildCreation = (
  nationwideOperationParticipation,
  user,
  isUserAutonomousClientDirector,
) => {
  const clients = nationwideOperationParticipation.clients.filter((client) =>
    isUserAutonomousClientDirector
      ? client.code === user.clientCode
      : client.status !== 'AUTONOMOUS' && client.alreadyParticipating === false,
  );
  return clients.map((client) => ({
    value: client.code,
    label: client.label,
  }));
};

/**
 *
 * @param {NationwideOperationParticipation | NationwideOperationParticipationChild} nationwideOperationParticipation
 */
export const participationActivationLabel = (nationwideOperationParticipation) => {
  if (nationwideOperationParticipation == null) return null;
  return nationwideOperationParticipation.status === enumParticipationChildStatus.VALIDATED.status
    ? 'nationwideOperations:clientNationwideOperation.details.reopen'
    : 'nationwideOperations:clientNationwideOperation.details.validate';
};

/**
 * @param {NationwideOperationParticipation} nationwideOperationParticipation
 */
export const getClientsAllowedForParticipationChildCreationAdmin = (nationwideOperationParticipation) => {
  const clients = nationwideOperationParticipation.clients.filter((client) => client.alreadyParticipating === false);
  return clients.map((client) => ({
    value: client.code,
    label: client.label,
  }));
};

/**
 *
 * @param {NationwideOperationParticipation} participation
 * @param {object} values
 */
export const participationChildFormToService = (values, participation) =>
  omit(
    {
      ...participation,
      ...values,
      chosenDeliveryClientCode: values.chosenDeliveryClient.value,
      billingAddressId: values.billingAddress.id,
      shippingAddressId: values.shippingAddress.id,
      clientCodes: participation.clients.map((client) => client.code),
    },
    ['chosenDeliveryClient', 'billingAddress', 'shippingAddress', 'clients', 'centers'],
  );
