import { useQuery } from '@tanstack/react-query';

// Services
import {
  getParticipations,
  getParticipation,
  getParticipationChildren,
  getParticipationChild,
} from './participationsService';

/**
 * @param {PaginationParams | {}} params
 * @param {UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult<NationwideOperationParticipation[]>}
 */
export const useGetParticipationsList = (params, options = {}) =>
  useQuery({
    queryKey: ['nationwide-operation-particpations', params, options],
    queryFn: () => getParticipations(params),
    ...options,
  });

/**
 *
 * @param {Object} params
 * @param {String} params.id
 * @param {UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult<NationwideOperationParticipation>}
 */
export const useGetParticipation = (params, options) =>
  useQuery({
    queryKey: ['nationwide-operation-particpation', params],
    queryFn: () => getParticipation(params.id),
    ...options,
  });

/**
 *
 * @param {Object} params
 * @param {String} params.id
 * @param {UseQueryOptions} options
 * @returns {import('@tanstack/react-query').UseQueryResult<NationwideOperationParticipationChild[]>}
 */
export const useGetParticipationChildren = (params, options = {}) =>
  useQuery({
    queryKey: ['nationwide-operation-participation-children', params.id],
    queryFn: () => getParticipationChildren(params.id),
    ...options,
  });

/**
 *
 * @param {Object} params
 * @returns {import('@tanstack/react-query').UseQueryResult<NationwideOperationParticipationChild>}
 */
export const useGetParticipationChild = (params, options = {}) =>
  useQuery({
    queryKey: ['nationwide-operation-participation-children', params],
    queryFn: () => getParticipationChild(params),
    ...options,
  });
