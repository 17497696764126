import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Icons
import ChevronDownIcon from '../../../public/icons/chevron_down.svg';

import styles from './CollapsibleBlock.module.scss';

function CollapsibleBlock({
  label, children, id, labelClass, chevronColor, isExpand, initialOpen,
}) {
  const collapseTrigger = useRef(null);
  const collapsedElement = useRef(null);
  const [opened, setOpened] = useState(initialOpen);
  const [maxHeight, setMaxHeight] = useState(opened || children == null
    ? `${collapsedElement.current?.scrollHeight}px` : 0);

  useEffect(() => {
    setMaxHeight(opened ? `${collapsedElement.current?.scrollHeight}px` : 0);
  }, [opened, isExpand, children]);

  return (
    <div>
      <button
        type="button"
        id={`${id}-collapseTrigger`}
        ref={collapseTrigger}
        onClick={() => setOpened(!opened)}
        className={`
          flex a-center j-spb c-pointer
          ${styles.trigger} ${opened ? styles.opened : ''} ${opened ? 'active' : ''}
          ${labelClass || ''}
        `}
      >
        {label}
        <ChevronDownIcon
          width={12}
          height={6}
          className={styles.chevron}
          style={{ minWidth: 12, color: chevronColor || 'currentColor' }}
        />
      </button>
      <div
        id={`${id}-collapsedElement`}
        className={styles.collapsed}
        style={{
          maxHeight,
        }}
        ref={collapsedElement}
      >
        {children}
      </div>
    </div>
  );
}

CollapsibleBlock.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  labelClass: PropTypes.string,
  isExpand: PropTypes.bool,
  initialOpen: PropTypes.bool,
};

CollapsibleBlock.defaultProps = {
  labelClass: null,
  isExpand: false,
  initialOpen: false,
};

export default CollapsibleBlock;
