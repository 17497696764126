// Utils
import { useTranslation } from 'next-i18next';
import { useAppContext } from '../store/AppContext';
import { isSOrder, sorderDomain } from '../utils/platform';

const useKeyAccountValues = () => {
  const { t } = useTranslation();
  const [{ keyAccountContext }] = useAppContext();

  const label = keyAccountContext?.displayKeyAccountClientInformationsToggle
    ? keyAccountContext.label
    : isSOrder()
      ? t(sorderDomain())
      : t('pneumaclic');

  const phoneNumber = keyAccountContext?.displayKeyAccountClientInformationsToggle
    ? keyAccountContext.contactFixPhone || keyAccountContext.contactMobilePhone || t('phoneNumber')
    : t('phoneNumber');

  return {
    ...keyAccountContext,
    label,
    phoneNumber,
  };
};

export default useKeyAccountValues;
