import React from 'react';

import styles from './RoleBadge.module.scss';

/**
 * @param {Object} props
 * @param {React.ReactNode} [props.icon]
 * @param {string} [props.label]
 */
function RoleBadge({ icon, label }) {
  return (
    <div className={`${styles.badge} flex a-center`}>
      {icon ? <span className={`${styles.icon} flex center m-r-15`}>{icon}</span> : null}
      <p className="fs-14 fw-500">{label}</p>
    </div>
  );
}

export default RoleBadge;
