import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

// Utils
import { NEXT } from '../../../src/constants/routes';

// Icons
import CartIcon from '../../../public/icons/cart.svg';

// Hooks
import useCart from '../../../src/hooks/useCart';

import styles from './Cart.module.scss';

function Cart({ cartConfig }) {
  const { isCartPopulated } = useCart();

  return (
    <Link
      href={NEXT.CHECKOUT('cart')}
      className={`
          ${styles.cart}
          flex center
          ${cartConfig?.whiteCart ? styles.white : ''}
          ${cartConfig?.noFixedCart ? styles.absolute : ''}
        `}
    >
      <>
        <CartIcon />
        <span className={`${styles.badge} ${isCartPopulated ? styles.show : ''}`} />
      </>
    </Link>
  );
}

Cart.propTypes = {
  cartConfig: PropTypes.objectOf(PropTypes.any),
};

Cart.defaultProps = {
  cartConfig: null,
};

export default Cart;
