'use client';

import Link from 'next/link';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';

// Utils
import { isGranted } from '../../../src/gate/PermissionsGate';
import { useAppContext } from '../../../src/store/AppContext';
import SCOPES from '../../../src/gate/scopes';
import displayName from '../../../src/utils/displayName';
import openModal from '../../../src/utils/modal/openModal';
import getCookieObject from '../../../src/utils/cookie';

// Icons
import UserIcon from '../../../public/icons/user.svg';
import HeadphoneIcon from '../../../public/icons/headphone.svg';
import ChevronDown from '../../../public/icons/chevron_down.svg';

// Hooks
import useRouter from '../../../src/hooks/useRouter';
import useMenuEntriesByRole from '../../../src/hooks/useMenuEntriesByRole';

// Components
import MenuEntry from '../../molecules/MenuEntry/MenuEntry';
import Footer from '../Footer/Footer';
import MobileHeader from '../../layout/MobileHeader/MobileHeader';

import styles from './Menu.module.scss';

/**
 *
 * @param {Object} props
 * @param {Object} [props.initialUser]
 * @param {boolean} props.overlay
 */
function Menu({ overlay, initialUser }) {
  const [{ keyAccountContext }] = useAppContext();
  const cookieUser = getCookieObject('user');
  const router = useRouter();
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const { t } = useTranslation();

  const user = initialUser || cookieUser;

  const [isUnfolded, setIsUnfolded] = useState(!overlay || isTablet);
  const [sidebarIsOpened, setSidebarIsOpened] = useState(false);

  useEffect(() => {
    setIsUnfolded(!overlay || isTablet);
    setSidebarIsOpened(isTablet ? sidebarIsOpened : false);
  }, [isTablet]);

  const menuEntries = useMenuEntriesByRole(user?.roles);

  // First menu entry is always the home page or the admin page, and is the root path
  const activeEntry =
    menuEntries.find((entry) => router.pathname.startsWith(entry.path) && entry.path !== menuEntries[0].path) ||
    menuEntries[0];

  const isMenuEntryActive = (/** @type {MenuEntry} */ entry) => entry.path === activeEntry.path;

  const isMenuEntryWithChildrenActive = (/** @type {MenuEntry} */ entry) =>
    entry.children.some((child) => router.pathname.includes(child.path));

  const account = {
    id: 'account',
    icon: <UserIcon />,
    label: displayName(`${user?.firstname} ${user?.lastname?.charAt(0)}.`),
    path: '',
  };

  return (
    <>
      {isTablet ? <MobileHeader sidebarIsOpened={sidebarIsOpened} setSidebarIsOpened={setSidebarIsOpened} /> : null}
      <div className={`${styles.sidebarContainer} ${sidebarIsOpened ? styles.opened : ''}`}>
        <div
          role="presentation"
          className={`${styles.sidebar} flex d-col`}
          onMouseEnter={() => {
            if (overlay && !isTablet) setIsUnfolded(true);
          }}
          onMouseLeave={() => {
            if (overlay && !isTablet) setIsUnfolded(false);
          }}
        >
          <div className={`${styles.sidebar_inner} flex d-col flex-auto`}>
            <div
              className={`
            ${styles.inner_pad}
            ${styles.logo_wrapper}
            ${styles.transition}
            ${!isUnfolded ? styles.hide : ''}
          `}
            >
              <Link href={isGranted(user, [SCOPES.canLogInAsAdmin]) ? '/admin' : '/'} passHref>
                <div className={`${styles.logo} flex center c-pointer`}>
                  {keyAccountContext?.logo ? (
                    <Image
                      width={300}
                      height={200}
                      alt={keyAccountContext?.label || ''}
                      src={keyAccountContext?.logo}
                    />
                  ) : null}
                </div>
              </Link>
            </div>
            <div className={styles.menu}>
              {user?.firstname && user?.lastname ? (
                <div className={`${styles.menu_user} m-t-40 m-b-40`}>
                  <div
                    className={`
                  ${styles.user_block}
                  ${router.pathname.includes('/account') && isUnfolded ? styles.active_block : ''}
                `}
                  >
                    <div className={`${styles.master_entry} flex a-center`}>
                      <div className={`${styles.user_entry} flex a-center`}>
                        <MenuEntry entry={account} isUnfolded={isUnfolded} withChildren />
                      </div>
                      {!isTablet ? <ChevronDown className={`${styles.arrow} m-l-auto`} /> : null}
                      <div className={`${styles.dropdown} ${styles.sub_entry} flex d-col`}>
                        {isGranted(user, [SCOPES.canViewMyAccount]) ? (
                          <Link
                            href={isGranted(user, [SCOPES.canViewMyAccountAsAdmin]) ? '/admin/account' : '/account'}
                          >
                            {t('menu.account')}
                          </Link>
                        ) : null}
                        {isGranted(user, [SCOPES.canViewCenters]) ? (
                          <Link href={`/centers/edit/company?id=${user?.clientId}`}>{t('menu.center')}</Link>
                        ) : null}
                        <button type="button" onClick={() => openModal('logoutConfirm')}>
                          {t('menu.logout')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="separator light" />
              <div className={`${styles.menu_more} m-t-40 m-b-40`}>
                <div className={styles.links}>
                  {menuEntries
                    .filter((entry) => !entry.isHidden)
                    .map((entry, i) =>
                      entry.children?.length ? (
                        <div
                          key={i}
                          className={`
                      ${styles.link}
                      ${styles.with_children}
                      ${isMenuEntryWithChildrenActive(entry) ? styles.active : null}
                      flex a-center
                    `}
                        >
                          <div className={`${styles.master_entry} flex a-center`}>
                            <MenuEntry
                              entry={entry}
                              isUnfolded={isUnfolded}
                              isActive={isMenuEntryWithChildrenActive(entry)}
                              withChildren
                            />
                            {!isTablet ? <ChevronDown className={`${styles.arrow} m-l-auto`} /> : null}
                            <div className={`${styles.dropdown} ${styles.sub_entry} flex d-col`}>
                              {entry.children.map((child, ci) => (
                                <Link href={child.path} key={ci} data-test={child.label}>
                                  {child.label}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Link
                          key={i}
                          href={entry.path}
                          data-test={entry.id}
                          className={`
                      ${styles.link}
                      ${isMenuEntryActive(entry) ? styles.active : null}
                      flex a-center
                    `}
                        >
                          <MenuEntry entry={entry} isActive={isMenuEntryActive(entry)} isUnfolded={isUnfolded} />
                        </Link>
                      ),
                    )}
                </div>
              </div>
            </div>
            {isGranted(user, [SCOPES.canViewFooter]) ? (
              <div className={`${styles.legals} m-t-auto`}>
                <div className="separator light" />
                <div className={`${styles.transition} ${!isUnfolded ? styles.hide : ''}`}>
                  <Footer />
                </div>
                <div
                  className={`${styles.legals_min} flex center ${styles.transition} ${isUnfolded ? styles.hide : ''}`}
                >
                  <HeadphoneIcon className={`${styles.transition} ${!isUnfolded ? styles.low_opacity : ''}`} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
