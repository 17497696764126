/**
 * @type {{ [key in NationwideOperationClientStatus]: { label: key, status: key, color: string }}}
 */
const enumNationwideOperationClientStatus = {
  GROUPED: {
    status: 'GROUPED',
    label: 'GROUPED',
    color: 'green',
  },
  AUTONOMOUS: {
    status: 'AUTONOMOUS',
    label: 'AUTONOMOUS',
    color: 'yellow',
  },
};

export default enumNationwideOperationClientStatus;
