import React from 'react';
import PropTypes from 'prop-types';

import styles from './Badge.module.scss';

/**
 * @param {object} props
 * @param {"primary"|"primary100"} [props.theme]
 * @param {"mini"} [props.size]
 * @param {string} [props.customColor]
 * @param {string} [props.className]
 */
function Badge({ theme, size, customColor, className }) {
  return (
    <span
      className={`
        ${styles.badge}
        ${theme != null ? styles[theme] : null}
        ${size === 'mini' ? styles.mini : ''}
        ${className}
      `}
      style={customColor ? { backgroundColor: customColor } : null}
    />
  );
}

Badge.propTypes = {
  theme: PropTypes.oneOf(['primary', 'primary100']),
  size: PropTypes.oneOf(['mini']),
  customColor: PropTypes.string,
};

Badge.defaultProps = {
  theme: null,
  size: null,
  customColor: null,
};

export default Badge;
