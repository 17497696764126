import capitalizeWord from './capitalizeWord';

const capitalizeName = (string) => {
  if (string) return string.split(' ').map((word) => capitalizeWord(word)).join(' ');
  return '';
};

const displayName = (name) => {
  if (name) return capitalizeName(name.toLowerCase());
  return '';
};

export default displayName;
