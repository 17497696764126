import api from '../../_api';

/**
 * @typedef {{
 *   status: NationwideOperationClientStatus,
 *   nationwideOperationTitle: string,
 *   manufacturerLabel: string
 * }} ParticipationFetchFilters
 */

/**
 * @param {PaginationParams & ParticipationFetchFilters} params
 * @returns {Promise<NationwideOperationParticipation[]>}
 */
export const getParticipations = async (data) => {
  const cleanedData = Object.entries(data).reduce((acc, [key, value]) => {
    acc[key] = value === '' ? null : value;
    return acc;
  }, {});
  const result = await api.get('/v1/nationwide-operation-participation', {
    params: cleanedData,
  });

  return result.data.nationwideOperationParticipations;
};

/**
 * @param {String} id
 * @returns {Promise<NationwideOperationParticipation>}
 */
export const getParticipation = async (id) => {
  const result = await api.get(`/v1/nationwide-operation-participation/${id}`);

  const nationwideOperation = result.data.nationwideOperationParticipation;

  return nationwideOperation;
};

/**
 * @param {Object} params
 * @param {string} params.id,
 * @returns {Promise<NationwideOperationParticipationChild[]>}
 */
export const getParticipationChildren = async (params) => {
  const result = await api.get(`/v1/nationwide-operations-participation/${params.id}/participation-child`);

  return result.data.nationwideOperationParticipationChildren;
};

/**
 *
 * @param {Object} params
 * @param {String} params.nationwideId
 * @param {String} params.childId
 * @returns {Promise<NationwideOperationParticipationChild>}
 */
export const getParticipationChild = async (params) => {
  const result = await api.get(
    `/v1/nationwide-operation-participation/${params.nationwideId}/participation-child/${params.childId}`,
  );

  return result.data.nationwideOperationParticipationChild;
};

/**
 * @param {NationwideOperationParticipationChild} params
 */
export const createParticipationChild = async ({ id, params }) =>
  api.post(`/v1/nationwide-operation-participation/${id}/participation-child`, params);

/**
 * @param {NationwideOperationParticipation} params
 */
export const updateParticipation = async ({ id, params }) =>
  api.put(`/v1/nationwide-operation-participation/${id}`, params);

/**
 * @param {NationwideOperationParticipationChild} params
 */
export const updateParticipationChild = async ({ id, participationId, params }) =>
  api.patch(`/v1/nationwide-operation-participation/${id}/participation-child/${participationId}`, params);

/**
 * @param {NationwideOperationParticipationProductSearch} params
 */
export const searchParticipationProduct = async ({ id, params }) =>
  api.post(`/v1/nationwide-operation-participation/${id}/product-search`, params);

/**
 * @param {NationwideOperationParticipation['id']} params
 */
export const submitParticipation = async ({ id }) => api.post(`/v1/nationwide-operation-participation/${id}/submit`);

/**
 *
 * @param {string} id
 * @param {string} participationId
 */
export const validateParticipationChild = async ({ id, participationId }) =>
  api.post(`/v1/nationwide-operation-participation/${participationId}/participation-child/${id}/validate`);

export const reopenParticipationChild = async ({ id, participationId }) =>
  api.post(`/v1/nationwide-operation-participation/${participationId}/participation-child/${id}/reopen`);

/**
 *
 * @param {object} params
 * @param {string} params.participationId
 * @param {object} params
 * @returns {Promise<Product[]>}
 */
export const getParticipationProduct = async ({ participationId, ...params }) => {
  const response = await api.post(
    `/v1/nationwide-operation-participation/${participationId}/calculate-product-prices`,
    {
      ...params,
    },
  );
  return response.data.products;
};

/**
 *
 * @param {object} params
 * @param {string} params.participationId
 * @param {object} params
 * @returns {Promise<Product[]>}
 */
export const searchNationwideOperationParticipationProducts = async ({ participationId, ...params }) => {
  const response = await api.post(`/v1/nationwide-operation-participation/${participationId}/product-search`, {
    pagination: {
      page: 1,
    },
    ...params,
  });
  return response.data.products;
};
