const enumParticipationChildStatus = {
  RUNNING: {
    status: 'RUNNING',
    label: 'participationChild.status.running',
    theme: 'green',
  },
  VALIDATED: {
    status: 'VALIDATED',
    label: 'participationChild.status.validated',
    theme: 'green',
  },
  TO_VALIDATE: {
    status: 'TO_VALIDATE',
    label: 'participationChild.status.toValidate',
    theme: 'yellow',
  },
  SENT: {
    status: 'SENT',
    label: 'participationChild.status.sent',
    theme: 'green',
  },
  CANCELLED: {
    status: 'CANCELLED',
    label: 'participationChild.status.cancelled',
    theme: 'grey',
  },
};

export default enumParticipationChildStatus;
