// Components
import Badge from '../../atoms/Badge/Badge';

import styles from './MenuEntry.module.scss';

/**
 * @param {object} props
 * @param {MenuEntry} props.entry
 * @param {boolean} props.isUnfolded
 * @param {boolean} [props.isActive]
 * @param {boolean} [props.withChildren]
 */
function MenuEntry({ entry, isUnfolded, isActive = false, withChildren = false }) {
  return (
    <span className={styles.entry}>
      <span className={`${styles.icon} ${styles.transition} ${isActive && !isUnfolded ? styles.active : ''} flex`}>
        {entry.icon}
      </span>
      {entry.badge ? <Badge theme="primary" className={styles.badge} /> : null}
      <span
        className={`
          ${styles.transition}
          ${!isUnfolded ? styles.hide : ''}
          ${withChildren ? 'p-r-20' : ''}
          p-t-8
        `}
      >
        {entry.label}
      </span>
    </span>
  );
}

export default MenuEntry;
