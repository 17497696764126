/* eslint-disable react/jsx-props-no-spreading */
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';

// Utils
import PermissionsGate, { isGranted } from '../gate/PermissionsGate';
import SCOPES from '../gate/scopes';
import displayName from '../utils/displayName';
import getCookieObject from '../utils/cookie';

// Icons
import AdminIcon from '../../public/icons/admin/admin.svg';

// Components
import FlashMessage from '../../components/molecules/FlashMessage/FlashMessage';
import Menu from '../../components/organisms/Menu/Menu';
import Cart from '../../components/layout/Cart/Cart';
import RoleBadge from '../../components/atoms/RoleBadge/RoleBadge';
import Button from '../../components/atoms/Button/Button';
import Alert from '../../components/organisms/Alert/Alert';

// Hooks
import useImpersonate from '../hooks/useImpersonate';

import styles from '../../styles/layout/SidebarLayout.module.scss';

/**
 *
 * @param {React.ElementType} Component
 * @param {Object} params
 * @param {boolean} params.overlay
 * @param {Object} [params.cart]
 * @param {boolean} [params.cart.showCart]
 * @param {boolean} [params.cart.whiteCart]
 * @param {boolean} [params.cart.noFixedCart]
 */
const withSidebar = (Component, { overlay, cart }) => {
  function Sidebar(props) {
    const { t } = useTranslation();
    const isTablet = useMediaQuery({ maxWidth: 1024 });
    const impersonateToken = getCookieObject('impersonateToken');
    const impersonateUser = getCookieObject('impersonateUser');
    const user = getCookieObject('user');
    const { handleImpersonateLogout } = useImpersonate();

    const roleBadgeLabel = () => {
      if (isGranted(user, [SCOPES.canViewKeyAccountRegionalAdminLabel])) {
        return t(t('admin.keyAccountRegionalAdminView'));
      }
      if (isGranted(user, [SCOPES.canViewKeyAccountAdminLabel])) return t(t('admin.keyAccountAdminView'));
      return t('admin.adminView');
    };

    if (!user) return null;

    return (
      <section className={styles.layout}>
        <Menu overlay={overlay} />
        <div className={`${styles.content} ${overlay ? styles.overlay : ''}`}>
          {impersonateToken?.token ? (
            <div className={`${styles.impersonate} flex a-center j-spb mobile-d-col mobile-a-start`}>
              <p
                className="m-r-20 mobile-m-r-0 mobile-m-b-20"
                dangerouslySetInnerHTML={{
                  __html: t('clients.navigateAs', { userName: displayName(`${user.firstname} ${user.lastname}.`) }),
                }}
              />

              <Button small onClick={handleImpersonateLogout}>
                {t('clients.logoutFrom')}
              </Button>
            </div>
          ) : null}

          {user?.testModeEnabled || impersonateUser?.testModeEnabled ? (
            <div className={styles.testMode}>
              <FlashMessage message={t('clients.testModeEnabled')} color="alert" />
            </div>
          ) : null}

          <Component {...props} />

          {!isTablet ? (
            <>
              <PermissionsGate scopes={[SCOPES.canAccessCart]}>
                {cart?.showCart ? <Cart cartConfig={cart} /> : null}
              </PermissionsGate>

              <PermissionsGate scopes={[SCOPES.canViewAdminDashboard]}>
                <RoleBadge icon={<AdminIcon />} label={roleBadgeLabel()} />
              </PermissionsGate>
            </>
          ) : null}

          {isGranted(user, [SCOPES.canViewNotificationAlert]) ? <Alert overlay={overlay} /> : null}
        </div>
      </section>
    );
  }

  return Sidebar;
};

export default withSidebar;
