/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

// Utils
import { useAppContext } from '../../../src/store/AppContext';
import { isGranted } from '../../../src/gate/PermissionsGate';
import SCOPES from '../../../src/gate/scopes';
import getCookieObject from '../../../src/utils/cookie';

// Images
import TyresBannerMobile from '../../../public/images/banners/tyres_mobile.jpg';
import RimsBannerMobile from '../../../public/images/banners/rims_mobile.png';
import ChainsBannerMobile from '../../../public/images/banners/chains_mobile.png';
import ConsumablesBannerMobile from '../../../public/images/banners/consumables_mobile.png';

// Icons
import UserIcon from '../../../public/icons/user.svg';

// Components
import Cart from '../Cart/Cart';

// Constants
import { CHAINS, CONSUMABLES, RIMS, TYRES, BATTERIES } from '../../../src/enum/search/modules';

import styles from './MobileHeader.module.scss';

/** @type {{ [x in ModuleType]?: any}} */
const defaultBanners = {
  tyres: TyresBannerMobile,
  rims: RimsBannerMobile,
  chains: ChainsBannerMobile,
  consumables: ConsumablesBannerMobile,
};

/**
 * @param {Object} props
 * @param {ReactState<boolean>[0]} props.sidebarIsOpened
 * @param {ReactState<boolean>[1]} props.setSidebarIsOpened
 */
function MobileHeader({ sidebarIsOpened, setSidebarIsOpened }) {
  const [{ homeBannerType, keyAccountContext }] = useAppContext();
  const user = getCookieObject('user');

  const [bannerByCategory, setBannerByCategory] = useState(null);

  useEffect(() => {
    if (keyAccountContext) {
      setBannerByCategory({
        tyres: keyAccountContext.tyrePicture,
        rims: keyAccountContext.rimPicture,
        chains: keyAccountContext.chainPicture,
        consumables: keyAccountContext.consumablePicture,
        batteries: keyAccountContext.batteryPicture,
      });
    }
  }, [keyAccountContext]);

  const getBannerByType = (/** @type {ModuleType} */ type) => (
    <Image
      priority
      src={bannerByCategory && bannerByCategory[type] ? bannerByCategory[type] : defaultBanners[type]}
      alt="Pneumaclic"
      fill
      style={{
        objectFit: 'cover',
      }}
      className={`${styles.banner_img} ${homeBannerType === type ? styles.current : ''}`}
    />
  );

  return (
    <div id="mobileHeader" className={`${styles.mobile_header} flex a-center j-spb`}>
      <div className={`${styles.banner} ${sidebarIsOpened ? styles.with_sidebar : null}`}>
        {getBannerByType(TYRES)}
        {getBannerByType(RIMS)}
        {getBannerByType(CHAINS)}
        {getBannerByType(CONSUMABLES)}
        {getBannerByType(BATTERIES)}
      </div>
      <div className={`${styles.header_content} flex a-center j-spb`}>
        <button
          type="button"
          className={`${styles.burger} ${sidebarIsOpened ? styles.cross : ''}`}
          onClick={() => setSidebarIsOpened(!sidebarIsOpened)}
        >
          <span />
          <span />
          <span />
        </button>
        <Link href={isGranted(user, [SCOPES.canLogInAsAdmin]) ? '/admin' : '/'}>
          <div className={styles.logo_wrapper}>
            <div className={styles.logo}>{keyAccountContext?.label || 'Pneumaclic'}</div>
          </div>
        </Link>
        <div className="flex a-center">
          <Link href="/account" className="flex center text-c-white p-5">
            <UserIcon />
          </Link>
          {isGranted(user, [SCOPES.canAccessCart]) ? <Cart /> : null}
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
