import { cloneElement } from 'react';

import Dashboard from '../../../public/icons/dashboard.svg';
import ThreeDotsVertical from '../../../public/icons/DotsThreeCircleVertical.svg';
import ColoredRefreshIcon from '../../../public/icons/refreshColored.svg';
import BoxIcon from '../../../public/icons/box.svg';

export const availableIcons = {
  dashboard: Dashboard,
  threeDotsVertical: ThreeDotsVertical,
  coloredRefresh: ColoredRefreshIcon,
  box: BoxIcon,
};

/**
 *
 * @param {Object} params
 * @param {keyof typeof availableIcons} params.name
 * @param {any} [params.props]
 */
// @ts-ignore
const Icon = ({ name, ...props }) => cloneElement(availableIcons[name](), props);

export default Icon;
