// Utils
import { ROLES } from '../gate/permissionMaps';
import { isSOrder } from '../utils/platform';

// Icons
import SearchIcon from '../../public/icons/search.svg';
import PackageIcon from '../../public/icons/box.svg';
import SuppliersIcon from '../../public/icons/supplier.svg';
import DashboardIcon from '../../public/icons/dashboard.svg';
import CustomerIcon from '../../public/icons/customer.svg';
import KeyAccountIcon from '../../public/icons/admin/key_account.svg';
import NationwideOperationIcon from '../../public/icons/3d_square.svg';
import AlertIcon from '../../public/icons/alert.svg';
import ShoppingIcon from '../../public/icons/shopping_bag.svg';

// Constants
import { NEXT } from '../constants/routes';

// Hooks
import useTranslation from '../../i18n/client';
import useNationwideOperationParticipation from './domain/useNationwideOperationParticipation';

// Services
import { useGetParticipationsList } from '../services/client/participations/participationsQueries';
import Icon from '../../components/atoms/Icon/Icon';

/**
 * @param {string[]} roles
 * @returns {MenuEntry[]}
 */
const useMenuEntriesByRole = (roles) => {
  const { t } = useTranslation();

  const participationsQuery = useGetParticipationsList(
    {},
    {
      enabled: roles?.some((role) => [ROLES.ROLE_MAIN_USER, ROLES.ROLE_DIRECTOR].includes(role)),
    },
  );

  const { isAnyParticipationRunning } = useNationwideOperationParticipation({
    nationwideOperationParticipation: participationsQuery.data,
  });

  if (roles == null) return [];

  const catalog = {
    id: 'catalog',
    label: t('menu.entries.products'),
    path: '/',
    icon: <SearchIcon />,
  };
  const directorCatalog = {
    id: 'directorCatalog',
    label: t('menu.entries.products'),
    path: '/catalog',
    icon: <SearchIcon />,
  };

  const dashboard = {
    id: 'dashboard',
    label: t('menu.entries.dashboard'),
    path: '/admin',
    icon: <DashboardIcon />,
  };

  const orders = {
    id: 'orders',
    label: t('menu.entries.orders'),
    path: '/orders',
    icon: <PackageIcon />,
  };

  const adminOrders = {
    id: 'adminOrders',
    label: t('menu.entries.orders'),
    path: '/admin/orders',
    icon: <PackageIcon />,
  };

  const adminCatalog = {
    id: 'adminCatalog',
    label: t('menu.entries.products'),
    path: '/admin/catalog/brands',
    icon: <ShoppingIcon />,
  };

  const suppliers = {
    id: 'suppliers',
    label: t('menu.entries.suppliers'),
    icon: <SuppliersIcon />,
    path: NEXT.SUPPLIERS.LIST(),
  };

  const superAdminSuppliers = {
    id: 'superAdminSuppliers',
    label: t('menu.entries.suppliers'),
    icon: <SuppliersIcon />,
    path: NEXT.SUPPLIERS.LIST(),
    children: [
      {
        label: t('menu.entries.suppliersList'),
        path: NEXT.SUPPLIERS.LIST(),
      },
      {
        label: t('menu.entries.suppliersMargins'),
        path: NEXT.SUPPLIERS.MARGINS.LIST(),
      },
    ],
  };

  const clients = {
    id: 'clients',
    label: t('menu.entries.clients'),
    path: '/admin/clients',
    icon: <CustomerIcon />,
  };

  const centers = {
    id: 'centers',
    label: t('menu.entries.centers'),
    path: '/centers',
    icon: <CustomerIcon />,
  };

  const keyAccountClients = {
    id: 'keyAccountClients',
    label: t('menu.entries.keyAccounts'),
    path: NEXT.KEY_ACCOUNTS.LIST(),
    icon: <KeyAccountIcon />,
  };

  const alerts = {
    id: 'alerts',
    label: t('menu.entries.alerts'),
    path: '/admin/alerts',
    icon: <AlertIcon />,
  };

  const adminNationwideOperations = {
    id: 'adminNationwideOperations',
    label: t('menu.entries.nationwideOperations'),
    path: NEXT.NATIONWIDE_OPERATIONS.LIST(),
    icon: <NationwideOperationIcon />,
  };

  const nationwideOperations = {
    id: 'nationwideOperations',
    label: t('menu.entries.nationwideOperations'),
    path: '/nationwide-operations',
    icon: <NationwideOperationIcon />,
    badge: isAnyParticipationRunning(participationsQuery.data),
  };

  const fluxClients = {
    id: 'fluxClients',
    label: t('menu.entries.fluxClients'),
    path: NEXT.FLUX_CLIENTS.LIST(),
    icon: <Icon name="coloredRefresh" />,
  };

  if (roles.includes(ROLES.ROLE_TECHNICAL_SUPER_ADMIN) || roles.includes(ROLES.ROLE_SUPER_ADMIN)) {
    if (isSOrder()) {
      return [
        dashboard,
        adminOrders,
        adminCatalog,
        clients,
        keyAccountClients,
        adminNationwideOperations,
        alerts,
        superAdminSuppliers,
      ];
    }
    return [dashboard, adminOrders, adminCatalog, clients, keyAccountClients, alerts, fluxClients, superAdminSuppliers];
  }

  if (roles.includes(ROLES.ROLE_ADMIN)) {
    if (isSOrder()) {
      return [dashboard, adminOrders, clients, keyAccountClients, suppliers];
    }
    return [dashboard, adminOrders, clients, keyAccountClients, fluxClients, suppliers];
  }

  if (
    roles.includes(ROLES.ROLE_KEY_ACCOUNT_CLIENT_ADMIN) ||
    roles.includes(ROLES.ROLE_KEY_ACCOUNT_CLIENT_REGIONAL_ADMIN)
  ) {
    if (isSOrder()) {
      return [dashboard, adminOrders, clients, adminNationwideOperations];
    }
    return [dashboard, adminOrders, clients];
  }

  if (roles.includes(ROLES.ROLE_MULTI_CENTER_DIRECTOR)) {
    if (isSOrder()) {
      return [dashboard, directorCatalog, orders, centers, nationwideOperations];
    }
    return [dashboard, directorCatalog, orders, centers];
  }

  if (
    roles.includes(ROLES.ROLE_CUSTOMER) ||
    roles.includes(ROLES.ROLE_DIRECTOR) ||
    roles.includes(ROLES.ROLE_MAIN_USER)
  ) {
    if (isSOrder() && participationsQuery.data?.length > 0) {
      return [catalog, orders, nationwideOperations];
    }
    return [catalog, orders];
  }

  if (roles.includes(ROLES.ROLE_USER)) {
    return [catalog];
  }

  return [];
};

export default useMenuEntriesByRole;
