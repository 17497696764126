/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

// Utils
import SCOPES from '../gate/scopes';
import { isGranted } from '../gate/PermissionsGate';
import { useAppContext } from '../store/AppContext';
import getCookieObject from '../utils/cookie';

// Hooks
import useLogout from '../hooks/useLogout';

// Services
import { getConnectedUser } from '../services/user/userService';

// Components
import SpinLoader from '../../components/atoms/SpinLoader/SpinLoader';

const withAuth = (Component) => {
  function Auth(props) {
    const [{ keyAccountContext }, stateDispatch] = useAppContext();
    const impersonateUser = getCookieObject('impersonateUser');
    const user = getCookieObject('user');
    const logout = useLogout();

    const getConnectedUserQuery = useQuery(['getConnectedUser', impersonateUser], getConnectedUser);

    useEffect(() => {
      if (getConnectedUserQuery.isSuccess) {
        if (getConnectedUserQuery.data?.data != null) {
          const connectedUser = getConnectedUserQuery.data.data;
          stateDispatch({ type: 'SET_USER', payload: connectedUser });
          Cookies.set('user', JSON.stringify(connectedUser));

          if (impersonateUser && connectedUser?.platformCode !== keyAccountContext?.code) {
            stateDispatch({ type: 'SET_KA_CONTEXT_RELOAD_CODE', payload: connectedUser.platformCode });
          }
        } else {
          logout();
        }
      }
      if (getConnectedUserQuery.isError) {
        logout();
      }
    }, [getConnectedUserQuery.isSuccess, getConnectedUserQuery.isError]);

    useEffect(() => {
      if (
        user &&
        !isGranted(user, [SCOPES.canLogInAsAdmin]) &&
        keyAccountContext &&
        keyAccountContext?.code !== user.platformCode &&
        !impersonateUser
      ) {
        logout();
      }
    }, [user, keyAccountContext]);

    if (user) return <Component {...props} />;
    return (
      <div className="w-100 flex center" style={{ height: '100vh' }}>
        <SpinLoader width={50} color={keyAccountContext?.primaryColor} />
      </div>
    );
  }

  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps;
  }

  return Auth;
};

export default withAuth;
