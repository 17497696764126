/* eslint-disable max-len */
import { useCallback, useMemo } from 'react';

// Utils
import getCookieObject from '../../../utils/cookie';

// Constants
import enumNationwideOperationClientStatus from '../../../enum/nationwideOperations/enumNationwideOperationClientStatus';
import enumParticipationChildStatus from '../../../enum/participations/enumParticipationChildStatus';

import * as functions from './nationwideOperationParticipationFunctions';

/**
 * @typedef {NationwideOperationParticipationChild} Child
 */

/**
 * @param {Object} params
 * @param {NationwideOperationParticipation | Child} params.nationwideOperationParticipation
 */
const useNationwideOperationParticipation = ({ nationwideOperationParticipation }) => {
  const user = getCookieObject('user');

  const orderedProducts = () => functions.orderedProducts(nationwideOperationParticipation);

  const minimumToOrder = () =>
    functions.minimumToOrder(nationwideOperationParticipation, nationwideOperationParticipation);

  const missingProducts = () => functions.missingProducts(nationwideOperationParticipation);

  const progress = () => functions.progress(nationwideOperationParticipation);

  /**
   *
   * All clients that are grouped should have the same status, so getting the first's status should be enough
   * @param {NationwideOperationParticipationChild['clients']} clients - GROUP ONLY clients array
   * @returns {enumNationwideOperationClientStatus['AUTONOMOUS'] | enumNationwideOperationClientStatus['GROUPED']}
   */
  const getClientsGroupStatus = (clients) => functions.getClientsGroupStatus(clients);

  const clientsWithUpdatedStatus = (clients, code, newStatus) => {
    const copiedClients = [...clients];
    const updatedClient = copiedClients.findIndex((client) => client.code === code);
    copiedClients[updatedClient].status = newStatus;

    return copiedClients;
  };

  /**
   * @return {boolean}
   */
  const isEveryParticipationChildValid = () => {
    if (
      nationwideOperationParticipation == null ||
      nationwideOperationParticipation.nationwideOperationParticipationChilds == null ||
      nationwideOperationParticipation.nationwideOperationParticipationChilds.length === 0 ||
      nationwideOperationParticipation.clients.some((client) => client.alreadyParticipating === false)
    ) {
      return false;
    }
    return nationwideOperationParticipation.nationwideOperationParticipationChilds.every(
      (child) => child.status.toUpperCase() === enumParticipationChildStatus.VALIDATED.status,
    );
  };

  /**
   * @param {Child} child
   * @returns {number}
   */
  function sumCartItemQuantity(child) {
    return child.cart.cartItems.reduce((total, item) => total + item.quantity, 0);
  }

  const isUserAutonomousClientDirector = () =>
    nationwideOperationParticipation?.clients != null &&
    nationwideOperationParticipation.clients.some(
      (client) =>
        client.code === user.clientCode && client.status === enumNationwideOperationClientStatus.AUTONOMOUS.status,
    );

  function getClientsAllowedForParticipationChildCreation() {
    if (nationwideOperationParticipation == null) return [];
    return functions.getClientsAllowedForParticipationChildCreation(
      nationwideOperationParticipation,
      user,
      isUserAutonomousClientDirector(),
    );
  }

  function getClientsAllowedForParticipationChildCreationAdmin() {
    if (nationwideOperationParticipation == null) return [];
    return functions.getClientsAllowedForParticipationChildCreationAdmin(nationwideOperationParticipation);
  }

  const isAnyParticipationRunning = (participations) => {
    if (participations == null) return false;
    return participations.some((child) => child.status === enumParticipationChildStatus.RUNNING.status);
  };

  const isAllowedToEditCart = () => {
    if (nationwideOperationParticipation == null) return false;
    return (
      nationwideOperationParticipation.allowedBrands.length > 0 ||
      nationwideOperationParticipation.allowedSegments.length > 0
    );
  };

  const participationActivationLabel = useMemo(
    () => functions.participationActivationLabel(nationwideOperationParticipation),
    [nationwideOperationParticipation],
  );

  const participationChildFormToService = useCallback(
    (values) => functions.participationChildFormToService(values, nationwideOperationParticipation),
    [nationwideOperationParticipation],
  );

  return {
    progress,
    orderedProducts,
    minimumToOrder,
    missingProducts,
    getClientsGroupStatus,
    clientsWithUpdatedStatus,
    isEveryParticipationChildValid,
    sumCartItemQuantity,
    getClientsAllowedForParticipationChildCreation,
    getClientsAllowedForParticipationChildCreationAdmin,
    isAnyParticipationRunning,
    isUserAutonomousClientDirector,
    isAllowedToEditCart,
    participationActivationLabel,
    participationChildFormToService,
  };
};

export default useNationwideOperationParticipation;
